import {useCartQuery, WebshopSettings} from '~/graphql/graphql';
import webshopSettingsQuery from '~/graphql/queries/webshop/webshopSettings.gql';

export function useCart() {
  const {result: cartData} = useCartQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false,
  }); // TODO update module in de toekomst (apollo issue: https://github.com/vuejs/apollo/issues/1455)
  const webshopSettingsData = useAsyncQuery(webshopSettingsQuery);

  const cart = computed(() => cartData.value?.cart);
  const webshopSettings = computed<WebshopSettings>(() => webshopSettingsData.data.value?.webshopSettings);
  const items = computed(() => cart.value?.cart_items ?? []);

  const deliveryFee = computed(() => webshopSettings.value?.delivery_fee);
  const minimumOrderAmount = computed(() => webshopSettings.value?.minimum_order);
  const forEveryXAmount = computed(() => webshopSettings.value?.for_every_x_amount);
  const getXEntercoins = computed(() => webshopSettings.value?.get_x_entercoins);
  const securedShipment = computed(() => webshopSettings.value?.secured_shipment);

  return {
    cart,
    items,
    deliveryFee,
    minimumOrderAmount,
    forEveryXAmount,
    getXEntercoins,
    securedShipment,
  };
}
